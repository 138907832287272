/**
 * Created by ultimate on 26/01/15.
 */
$(document).ready(function(){

    $('#menu-toggle').on('click', function() {
        $('body').toggleClass('sticky');
        $(this).closest('.menu-bar').toggleClass('open');
    });

    if ($('body').hasClass('home')) {
        var open = false;
        $(".contact-us-button").click(function () {
            $("html, body").animate({scrollTop: $("#contact-button").offset().top - 30});
            $(".contact-us-bar").slideToggle();
            if (open === false) {
                $('.intro-bar').animate({
                    height: "-=43"
                }, "slow");
                open = true;
            } else {
                $("html, body").animate({scrollTop: "0px"});
                $('.intro-bar').animate({
                    height: "+=43"
                }, "slow");
                open = false;
            }
        });
    }

    if ($('body').hasClass('tax-service')) {
        $('#service-title').on('click', function() {
            $('body').toggleClass('sticky');
            $(this).closest('.page-banner').toggleClass('open');
        });
    }
});